import React, { useContext, useEffect, useMemo, useRef } from 'react';
import './ModalView.scss';
import { classNameBuilder } from '../../utils/className';
import { CloseIc, CloseIconWithoutBorder } from '../../assets/icons/svgIconsForJSX';
import { ModalViewContext } from '../../context/modalView/ModalViewContext';

export interface IProps {
    variant?: 'modal' | 'alert' | 'video' | 'modal-form' | 'pop-up' | 'modal-whitespace' | 'cookie';
    onClickClose?: () => void;
    position?: 'center' | 'right';
    closeBtn?: {
        isBackground?: boolean;
        isButton?: boolean;
    };
    isBackground?: boolean;
    isScheduler?: boolean;
    children?: React.ReactNode | undefined;
    className?: string;
    closeSeconds?: number;
    theme?: 'light' | 'dark';
    isResizeTimer?: boolean;
    style?: React.CSSProperties;
}

const ModalView: React.FC<IProps> = ({
    variant = 'modal',
    position,
    closeBtn,
    children,
    isBackground = false,
    isScheduler = false,
    onClickClose,
    className,
    closeSeconds = 5000,
    theme,
    isResizeTimer = false, // when we have a content that larger than screen height
    style,
}) => {
    const cn = classNameBuilder('modal-view');

    const { closeModal } = useContext(ModalViewContext);
    const contentRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        let timer: NodeJS.Timeout;
        if (isScheduler) {
            timer = setTimeout(() => {
                document.body.style.overflowY = 'visible';
                closeModal();
            }, closeSeconds);
            onClickClose && onClickClose();
        }
        return () => {
            clearTimeout(timer);
        };
    }, []);

    useEffect(() => {
        const current = contentRef?.current;

        let observer: IntersectionObserver | null = null;

        if (current && isResizeTimer) {
            observer = new IntersectionObserver(([entry]) => {
                if (entry.isIntersecting) {
                    let resizeTimer: NodeJS.Timeout | null = null;

                    if (current) {
                        if (resizeTimer === null) {
                            resizeTimer = setTimeout(() => {
                                if (window.innerHeight < (current.offsetHeight || 0) + 92) {
                                    current.style.top = '80px';
                                    current.style.transform = 'translate(-50%, 0)';
                                } else {
                                    current.style.top = '50%';
                                    current.style.transform = 'translate(-50%, -50%)';
                                }
                                resizeTimer = null;
                            }, 1000);
                        }
                    }
                }
            });
            observer.observe(current);
        }

        return () => {
            if (observer && current) {
                observer.unobserve(current);
            }
        };
    }, [position, variant]);

    if (isBackground) document.body.style.overflowY = 'hidden';
    else document.body.style.overflowY = 'visible';

    const onAction = () => {
        document.body.style.overflowY = 'visible';
        setTimeout(() => {
            closeModal();
        }, 100);
        onClickClose && onClickClose();
    };

    const isDarkForAlertVariant = useMemo(() => {
        if (variant === 'alert') {
            const pathname = window.location.pathname;
            const isDark = () => {
                switch (pathname) {
                    case '/news':
                        return true;
                    case '/department/1':
                        return true;
                    case '/vacancies':
                        return false;
                    case '/vacancies/1':
                        return true;
                    case '/advantages-conditions':
                        return true;
                }
                return (
                    pathname.includes('/vacancy-detail/') ||
                    pathname.includes('/internship-detail/') ||
                    pathname.includes('/news/')
                );
            };

            return theme ?? (isDark() ? 'light' : 'dark');
        }
        return theme;
    }, [theme, variant]);

    return (
        <div
            className={cn('', {
                active: true,
                background: isBackground,
            })}
            onClick={() => closeBtn?.isBackground && onAction()}>
            <div
                ref={contentRef}
                className={`${cn('content', {
                    active: true,
                    right: position === 'right',
                    button: position === 'right',
                    center: position === 'center',
                    variant: variant,
                    theme: isDarkForAlertVariant,
                })} ${className}`}
                onClick={(e) => e.stopPropagation()}
                style={style}>
                <CloseIconWithoutBorder onClick={onAction} className={cn('close-btn-alert')} />
                {closeBtn?.isButton && (
                    <button className={cn('close-btn', { dark: !isBackground })} onClick={onAction}>
                        <p>Закрыть</p>
                        <CloseIc className={cn('close-btn--icon')} />
                    </button>
                )}
                {children}
            </div>
        </div>
    );
};

export default ModalView;
