import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { ModalViewContextProvider } from './context/modalView/ModalViewContextProvider';
import { GlobalStateContextProvider } from './context/globalState/GlobalStateContextProvider';
import { AuthContextProvider } from './components/auth/AuthContext';
import { DictionariesContextProvider } from './context/dictionaries/DictionariesContextProvider';

ReactDOM.render(
    <React.StrictMode>
        <AuthContextProvider>
            <GlobalStateContextProvider>
                <ModalViewContextProvider>
                    <DictionariesContextProvider>
                        <App />
                    </DictionariesContextProvider>
                </ModalViewContextProvider>
            </GlobalStateContextProvider>
        </AuthContextProvider>
    </React.StrictMode>,
    document.getElementById('root'),
);
