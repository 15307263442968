import React, { useState } from 'react';
import ModalView from '../../components/Modal/ModalView';
import { ModalViewContext } from './ModalViewContext';
import { IProps } from '../../components/Modal/ModalView';

export const ModalViewContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
    const [modalOpened, setModalOpened] = useState(false);
    const [modalContent, setModalContent] = useState<any>(null);

    const openModal = (modalConfig: IProps) => {
        setModalContent(modalConfig);
        setModalOpened(true);
    };

    const closeModal = () => {
        document.body.style.overflowY = 'visible';
        setModalOpened(false);
    };

    const valueModalProvider = {
        openModal,
        closeModal,
    };

    return (
        <ModalViewContext.Provider value={valueModalProvider}>
            {modalOpened && <ModalView {...modalContent} />}
            {children}
        </ModalViewContext.Provider>
    );
};
