import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import get from 'lodash/get';

const request = axios.create({
    xsrfCookieName: 'csrftoken',
    xsrfHeaderName: 'X-CSRFToken',
});

request.interceptors.request.use((config: AxiosRequestConfig) => {
    config.headers!['Accept-Language'] = 'ru';
    return config;
});

export { request };

export function getErrorMsg(defaultMsg: string, rejection: AxiosError): string {
    const data = get(rejection, 'response.data', {});
    let msg = defaultMsg;

    if (data.detail) {
        msg = data.detail;
    } else if (data.non_field_errors) {
        msg = data.non_field_errors.join('. ');
    }

    return msg;
}
