import http from '../config/http';
import { IUserDto } from '../models/User/IUserDto';
import { ApiResponse } from './ApiTypes';

const basicSecureUrl = '/api/v1/secure/user';
const basicUrl = '/api/v1/user';

function identifyMe() {
    return http.get(basicSecureUrl + '/identify-me');
}

function updatePassword(login: any, params?: Record<string, unknown>) {
    return http.post(basicUrl + '/update-password', login, { params });
}

function changePassword(login: any, params?: Record<string, unknown>) {
    return http.post(basicSecureUrl + '/change-password', login, { params });
}

function signIn(login: any, params?: Record<string, unknown>) {
    return http.post(basicUrl + '/sign-in', login, { params });
}

function signUp(register: any, params?: Record<string, unknown>) {
    return http.post(basicUrl + '/sign-up', register, { params });
}

function logout(params?: Record<string, unknown>) {
    return http.delete(basicUrl + '/logout', { params });
}

function recoveryPassword(email: string) {
    return http.post(basicUrl + `/recovery-password/${email}`);
}

function restorePassword(token: any) {
    return http.get(basicSecureUrl + `/restore/${token}`);
}

function activate(token: any) {
    return http.get(basicUrl + `/activate/${token}`);
}

function saveUserPersonalAttachment(user: FormData) {
    return http.post(`${basicSecureUrl}/profile/attachment`, user);
}

function deletePersonalAttachment(id: number): Promise<ApiResponse<{}>> {
    return http.delete(`${basicSecureUrl}/profile/delete-attachment/${id}`);
}

function saveUserPersonal(user: IUserDto): Promise<ApiResponse<IUserDto>> {
    return http.post(`${basicSecureUrl}/profile/personal`, user);
}

function saveUserContact(user: IUserDto): Promise<ApiResponse<IUserDto>> {
    return http.post(`${basicSecureUrl}/profile/contact`, user);
}

function saveUserPassword(user: IUserDto): Promise<ApiResponse<IUserDto>> {
    return http.post(`${basicSecureUrl}/profile/password`, user);
}

function saveUserProfileOrigin(user: IUserDto): Promise<ApiResponse<IUserDto>> {
    return http.post(`${basicSecureUrl}/profile/origin`, user);
}

function saveUserSocial(user: IUserDto): Promise<ApiResponse<IUserDto>> {
    return http.post(`${basicSecureUrl}/profile/social`, user);
}

function getById(id: number): Promise<ApiResponse<IUserDto>> {
    return http.get(`${basicSecureUrl}/${id}`);
}

function whoami(): Promise<ApiResponse<IUserDto>> {
    return http.get(`${basicSecureUrl}/whoami`);
}

export const userManager = {
    identifyMe,
    signIn,
    signUp,
    activate,
    recoveryPassword,
    saveUserPersonalAttachment,
    deletePersonalAttachment,
    saveUserPersonal,
    saveUserContact,
    saveUserPassword,
    saveUserProfileOrigin,
    saveUserSocial,
    getById,
    restorePassword,
    updatePassword,
    logout,
    changePassword,
    whoami,
};
