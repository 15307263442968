import { useRef, lazy, Suspense, useEffect } from 'react';
import './assets/styles/main.scss';
import {
    BrowserRouter as Router,
    Route,
    Routes,
    useLocation,
    useNavigationType,
    createRoutesFromChildren,
    matchRoutes,
} from 'react-router-dom';
import * as Sentry from '@sentry/react';

const Navbar = lazy(() => import('./components/Navbar/Navbar'));
const HomeView = lazy(() => import('./views/Home/HomeView'));
const LoginView = lazy(() => import('./views/SignIn/Login/LoginView'));
const RegistrationView = lazy(() => import('./views/SignIn/Registration/RegistrationView'));
const AboutView = lazy(() => import('./views/About/AboutView'));
const News = lazy(() => import('./views/News/News'));
const Education = lazy(() => import('./views/Education/Education'));
const NewsDetail = lazy(() => import('./views/News/NewsDetail/NewsDetail'));
const DepartmentDetail = lazy(
    () => import('./components/Blocks/Departments/DepartmentDetail/DepartmentDetail'),
);
const NotFound = lazy(() => import('./views/404/NotFound'));
const VacancyView = lazy(() => import('./views/Vacancy/VacancyView'));
const VacancyDetail = lazy(() => import('./views/Vacancy/VacancyDetail/VacancyDetail'));
const Help = lazy(() => import('./views/Help/Help'));
const AdvantagesConditions = lazy(
    () => import('./views/AdvantagesConditions/AdvantagesConditions'),
);
const Cabinet = lazy(() => import('./views/Cabinet/Cabinet'));
const Response = lazy(() => import('./views/Cabinet/Response/Response'));
const ResponseDetail = lazy(() => import('./views/Cabinet/Response/ResponseDetail/ResponseDetail'));
const Favorites = lazy(() => import('./views/Cabinet/Favorites/Favorites'));
const Resume = lazy(() => import('./views/Cabinet/Resume/Resume'));
const Mailing = lazy(() => import('./views/Cabinet/Mailing/Mailing'));
const Setting = lazy(() => import('./views/Cabinet/Setting/Setting'));
const ProjectView = lazy(() => import('./views/Project/ProjectView'));
const ContactView = lazy(() => import('./views/Contact/ContactView'));
const InternshipsView = lazy(() => import('./views/Internships/InternshipsView'));
const ScholarshipView = lazy(() => import('./views/Scholarship/ScholarshipView'));
const ResetPassword = lazy(() => import('./views/SignIn/ResetPassword/ResetPassword'));
const SendPassword = lazy(() => import('./views/SignIn/SendPassword/SendPassword'));
const Office = lazy(() => import('./components/Blocks/Office/Office'));
const ReportErrorView = lazy(() => import('./components/Modal/ReportErrorView/ReportErrorView'));
const AskQuestionView = lazy(() => import('./components/Modal/AskQuestionView/AskQuestionView'));
const Schools = lazy(() => import('./views/Schools/Schools'));
const ScrollToTop = lazy(() => import('./components/Scroll/ScrollToTop'));
const PolicyView = lazy(() => import('./views/Policy/PolicyView'));
const SendCv = lazy(() => import('./components/Modal/SendCv/SendCv'));
const Activate = lazy(() => import('./views/Cabinet/Activate/Activate'));
const InternshipDetail = lazy(() => import('./views/Vacancy/InternshipDetail/InternshipDetail'));
const RecoveryPassword = lazy(() => import('./views/Cabinet/Recovery/RecoveryPassword'));
const ExpiredToken = lazy(() => import('./views/SignIn/ExpiredToken/ExpiredToken'));
const ConfirmRegistration = lazy(
    () => import('./views/SignIn/ConfirmRegistration/ConfirmRegistration'),
);
const VacancyNotFound = lazy(() => import('./components/Blocks/VacancyNotFound/VacancyNotFound'));
const BlockAccount = lazy(() => import('./views/SignIn/BlockAccount/BlockAccount'));
const RequireAuth = lazy(() => import('./components/auth/RequireAuth'));

function App() {
    const linkFrom = useRef('');

    Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DSN || '',
        integrations: [
            new Sentry.BrowserTracing({
                routingInstrumentation: Sentry.reactRouterV6Instrumentation(
                    useEffect,
                    useLocation,
                    useNavigationType,
                    createRoutesFromChildren,
                    matchRoutes,
                ),
            }),
        ],
        enableTracing: false,
    });

    const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

    return (
        <Router>
            <Suspense fallback={<></>}>
                <ScrollToTop />
                <Navbar />
                <SentryRoutes>
                    <Route path="/" element={<HomeView />} />

                    <Route path="/login" element={<LoginView linkFrom={linkFrom} />} />
                    <Route path="/login/:unsubscribe" element={<LoginView linkFrom={linkFrom} />} />
                    <Route path="/reset-password" element={<ResetPassword linkFrom={linkFrom} />} />
                    <Route path="/send-password" element={<SendPassword />} />
                    <Route path="/register" element={<RegistrationView />} />
                    <Route path="/activate/:token" element={<Activate />} />
                    <Route path="/restore/:token" element={<RecoveryPassword />} />
                    <Route path="/expired-token" element={<ExpiredToken />} />
                    <Route path="/block-account" element={<BlockAccount />} />

                    <Route path="/vacancies" element={<VacancyView />} />

                    {/*TODO Вероятно скоро нужно будет объединить в один роут*/}
                    <Route path="/internship-detail/:id" element={<InternshipDetail />} />
                    <Route path="/vacancy-detail/:id" element={<VacancyDetail />} />

                    <Route path="/news" element={<News />} />
                    <Route path="/news/:id" element={<NewsDetail />} />

                    <Route path="/projects" element={<ProjectView />} />
                    <Route path="/about" element={<AboutView />} />
                    <Route path="/education" element={<Education />} />
                    <Route path="/department/:type" element={<DepartmentDetail />} />
                    <Route path="/help" element={<Help />} />
                    <Route path="/advantages-conditions" element={<AdvantagesConditions />} />
                    <Route path="/confirm-registration" element={<ConfirmRegistration />} />
                    <Route path="/contacts" element={<ContactView />} />
                    <Route path="/office/:id" element={<Office />} />
                    <Route path="/internships" element={<InternshipsView />} />
                    <Route path="/scholarship" element={<ScholarshipView />} />
                    <Route path="/schools" element={<Schools />} />
                    <Route path="/policy/:type" element={<PolicyView />} />

                    <Route path="/report-error" element={<ReportErrorView linkFrom={linkFrom} />} />
                    <Route path="/ask-question" element={<AskQuestionView linkFrom={linkFrom} />} />
                    <Route path="/send-cv" element={<SendCv linkFrom={linkFrom} />} />

                    <Route
                        path="/cabinet/:id"
                        element={
                            <RequireAuth>
                                <Cabinet />
                            </RequireAuth>
                        }>
                        <Route
                            path=""
                            element={
                                <RequireAuth>
                                    <Response />
                                </RequireAuth>
                            }
                        />
                        <Route
                            path="detail/:type/:id"
                            element={
                                <RequireAuth>
                                    <ResponseDetail />
                                </RequireAuth>
                            }
                        />
                        {/* <Route path="notification" element={<NotificationAll />} /> */}
                        <Route
                            path="resume"
                            element={
                                <RequireAuth>
                                    <Resume />
                                </RequireAuth>
                            }
                        />
                        <Route
                            path="mailing"
                            element={
                                <RequireAuth>
                                    <Mailing />
                                </RequireAuth>
                            }
                        />
                        <Route
                            path="favorites"
                            element={
                                <RequireAuth>
                                    <Favorites />
                                </RequireAuth>
                            }
                        />
                        <Route
                            path="setting"
                            element={
                                <RequireAuth>
                                    <Setting />
                                </RequireAuth>
                            }
                        />
                    </Route>

                    <Route path="/vacancy-not-found" element={<VacancyNotFound />} />
                    <Route path="/error" element={<NotFound />} />
                    <Route path="/*" element={<NotFound />} />
                </SentryRoutes>
            </Suspense>
        </Router>
    );
}

export default App;
