import { createContext } from 'react';
import { IDirection } from '../../models/Directions/IDirection';

export type IDictionariesContext = {
    directions: IDirection[];
};

export const DictionariesContext = createContext<IDictionariesContext>({
    directions: [],
});
