import axios, { AxiosError } from 'axios';

export class HTTPError<T> {
    public message: string;
    public statusCode: number;
    public responseData?: T;

    constructor(error: AxiosError<{ message?: string }>) {
        const { response } = error;
        let message = 'UNKNOWN ERROR';
        let statusCode = 0;
        let data: any;
        if (response) {
            statusCode = response.status;
            message = response.statusText;
            if (response.data) {
                if (response.data.message) {
                    message = response.data.message;
                }
                data = response.data;
            }
        }
        this.message = message;
        this.statusCode = statusCode;
        this.responseData = data as T;
    }
}

function constructAxios() {
    const axiosInstance = axios.create({
        baseURL: process.env.REACT_APP_API_URL,
        headers: {
            'Content-Type': 'application/json',
        },
        withCredentials: true,
    });

    return axiosInstance;
}

const http = constructAxios();
export default http;
