import React, { useState } from 'react';
import { GlobalStateContext } from './GlobalStateContext';

export const GlobalStateContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
    const [fullSizeHeader, setFullSizeHeader] = useState(false);
    return (
        <GlobalStateContext.Provider value={{ fullSizeHeader, setFullSizeHeader }}>
            {children}
        </GlobalStateContext.Provider>
    );
};
