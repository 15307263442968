import React, { useState, useEffect } from 'react';
import { DictionariesContext } from './DictionariesContext';

import { directionManager } from '../../services/directionService';
import { IDirection } from '../../models/Directions/IDirection';

export const DictionariesContextProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
    const [directions, setDirections] = useState<IDirection[]>([]);

    useEffect(() => {
        directionManager
            .getAllDirection()
            .then((response) => setDirections(response.data.result))
            .catch((err) => {
                // console.log(err)
            });
    }, []);

    return (
        <DictionariesContext.Provider value={{ directions: directions }}>
            {children}
        </DictionariesContext.Provider>
    );
};
