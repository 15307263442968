import { AxiosResponse } from 'axios';
import React, { createContext, useCallback, useEffect, useState } from 'react';
import http, { HTTPError } from '../../config/http';
import { IUserDto } from '../../models/User/IUserDto';
import { userManager } from '../../services/userService';
import { IAuthContext } from './types';
import useCsrfToken from './useCsrfToken';

export const initialValues = {
    user: null,
    isAuthorized: () => false,
    setUser: () => {},
    onDeleteUserHandler: () => {},
    csrfToken: null,
    isInitialized: false,
};

export const AuthContext = createContext<IAuthContext>(initialValues);
interface IAuthContextProvider {
    children: React.ReactNode;
}
const AuthContextProvider: React.FC<IAuthContextProvider> = ({ children }) => {
    const [user, setUser] = useState<IAuthContext['user']>(initialValues['user']);
    const [isInitialized, setIsInitialized] = useState<IAuthContext['isInitialized']>(
        initialValues['isInitialized'],
    );

    const { csrfTokenHandler } = useCsrfToken();

    const onDeleteUserHandler = () => {
        setUser(null);
    };

    const isAuthorized = () => {
        return user !== null;
    };

    const __handleResponse = useCallback(
        (response: AxiosResponse<{ result: IUserDto }>) => {
            const user = response.data.result;
            csrfTokenHandler(response);
            setUser(user);
        },
        [csrfTokenHandler],
    );

    const refresh = useCallback(async () => {
        try {
            const response = await userManager.whoami();
            __handleResponse(response);
        } catch (e) {
            // console.log('e', e);
        } finally {
            setIsInitialized(true);
        }
    }, [__handleResponse]);

    useEffect(() => {
        refresh();
    }, []);

    const invalidate = useCallback(() => {
        csrfTokenHandler(null);
        setUser(null);
    }, [csrfTokenHandler]);

    useEffect(() => {
        http.interceptors.response.use(
            (response) => response,
            async (error) => {
                const customError = new HTTPError(error);
                if (customError.statusCode === 401 || customError.statusCode === 403) {
                    invalidate();
                }

                return Promise.reject(customError);
            },
        );
    }, [invalidate]);

    return (
        <AuthContext.Provider
            value={{
                user,
                isAuthorized,
                setUser,
                onDeleteUserHandler,
                isInitialized,
            }}>
            {children}{' '}
        </AuthContext.Provider>
    );
};

export { AuthContextProvider };
