import http from '../config/http';
import { request } from '../utils/request';

const API_URL = 'localhost:8081/api/v1/';
const DIRECTION_URL = API_URL + 'directions/';

const basicUrl = '/api/v1/directions';

const getAllDirection = () => {
    return http.get(basicUrl);
};

export const directionManager = {
    get,
    getAllDirection,
};
function get(params?: Record<string, unknown>) {
    return request.get(DIRECTION_URL, { params });
}
