import { AxiosResponse } from 'axios';
import { useCallback, useEffect, useState } from 'react';
import http from '../../config/http';
import { initialValues } from './AuthContext';
import { CSRF_TOKEN_HEADER } from './consts';

const useCsrfToken = () => {
    const [csrfToken, setCsrfToken] = useState<string | null>(initialValues['csrfToken']);

    const csrfTokenHandler = useCallback((response: AxiosResponse | null) => {
        const token = response ? response.headers[CSRF_TOKEN_HEADER] : null;
        setCsrfToken(token);
    }, []);

    useEffect(() => {
        http.interceptors.request.use(async (requestConfig) => {
            if (
                requestConfig.method?.toLowerCase() !== 'get' &&
                csrfToken &&
                requestConfig.headers
            ) {
                requestConfig.headers['X-CSRF-TOKEN'] = csrfToken;
            }

            return requestConfig;
        });
    }, [csrfToken]);

    return { csrfTokenHandler };
};

export default useCsrfToken;
